import React, { Fragment, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Navigation } from './Navigation';
import { Footer } from './Footer';
import { ImageProvider } from './Image';
import '../analytics';

const Container = styled.main``;

type PageProps = {
  children: ReactNode;
  isRoot?: boolean;
};

const BasePage = ({ children, isRoot }: PageProps) => {
  return (
    <ImageProvider>
      <Navigation isRoot={isRoot} />
      <Container>
        {children}
        <Footer />
      </Container>
    </ImageProvider>
  );
};

export const RootPage = ({ children }: PageProps) => (
  <BasePage isRoot>{children}</BasePage>
);

export const Page = ({ children }: PageProps) => (
  <BasePage>{children}</BasePage>
);
