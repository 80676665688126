export const COL_MAIN = '#F7F5E9';
export const COL_WHITE = '#FFF';
export const COL_BRIGHT = '#D4E2CB';
export const COL_MEDIUM = '#A0DCD5';
export const COL_DARK = '#55948E';
export const COL_TEXT = '#2F3C39';
export const WIDTH_CONTENT = '1280px';
export const S = '0.2rem';
export const M = '0.4rem';
export const L = '1rem';
export const XL = '4rem';
export const PADDING_CONTENT = L;
export const FONT_HEADER = ['PT Sans Narrow', 'Arial Narrow', 'sans-serif'];
export const FONT_BODY = ['Georgia', 'serif'];
export const BREAKPOINT = '@media (max-width: 520px)';
export const BREAKPOINT_LARGE = '@media (max-width: 960px)';

export const TYPOGRAPHY = {
  baseFontSize: '20px',
  baseLineHeight: 1.55,
  googleFonts: [
    {
      name: 'PT Sans Narrow',
      styles: ['400', '700'],
    },
  ],
  headerFontFamily: FONT_HEADER,
  bodyFontFamily: FONT_BODY,
};
