import React, { Fragment, ReactNode } from 'react';
import styled from '@emotion/styled';
import { COL_MAIN, WIDTH_CONTENT, PADDING_CONTENT } from '../variables';

const OuterSection = styled.section`
  background: ${({ color }) => color || COL_MAIN};
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const InnerSection = styled.div`
  width: ${WIDTH_CONTENT};
  max-width: 100%;
  padding: ${PADDING_CONTENT};
`;

type SectionProps = {
  children?: ReactNode;
  color?: string;
  className?: string;
  id?: string;
};

export const Section = ({ children, color, id, className }: SectionProps) => {
  return (
    <section id={id}>
      <OuterSection color={color} className={className}>
        <InnerSection>{children}</InnerSection>
      </OuterSection>
    </section>
  );
};
