import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { FONT_HEADER, COL_MAIN, COL_TEXT, BREAKPOINT } from '../variables';

interface FlagProps {
  inverted?: boolean;
  disabled?: boolean;
  event?: string;
  href?: string;
  children?: ReactNode;
  target?: '_blank';
}

export const RoofLine = styled.div`
  font-family: ${FONT_HEADER.join(', ')};
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-top: 0.55rem;
  color: ${({ inverted }: FlagProps) => (inverted ? COL_MAIN : COL_TEXT)};
`;

export const Title = styled.h2`
  text-transform: uppercase;
  font-size: 3rem;
  color: ${({ inverted }: FlagProps) => (inverted ? COL_MAIN : COL_TEXT)};

  ${BREAKPOINT} {
    font-size: 2.5rem;
  }
`;

export const Lead = styled.p`
  color: ${({ inverted }: FlagProps) => (inverted ? COL_MAIN : COL_TEXT)};
`;

const StyledButton = styled.a`
  color: ${({ inverted }: FlagProps) => (inverted ? COL_MAIN : COL_TEXT)};
  opacity: ${({ disabled }: FlagProps) => (disabled ? 0.5 : 1)};
  display: inline-block;
  padding: 0.1rem 1rem;
  margin-bottom: 0.4rem;
  border: 2px solid
    ${({ inverted }: FlagProps) => (inverted ? COL_MAIN : COL_TEXT)};
  font-family: ${FONT_HEADER.join(', ')};
  font-weight: bold;
  border-radius: 1rem;
  text-decoration: none;

  &:hover {
    background: ${({ disabled }: FlagProps) => (disabled ? null : COL_TEXT)};
    color: ${({ disabled }: FlagProps) => (disabled ? null : COL_MAIN)};
    border-color: ${({ disabled }: FlagProps) => (disabled ? null : COL_TEXT)};
  }
`;

/// <reference types="someLib" />
var dataLayer: any[];

export const Button = (props: FlagProps) => (
  <StyledButton
    target="_blank"
    rel="nofollow noopener"
    onClick={() => {
      dataLayer &&
        dataLayer.push({
          event: 'interaction',
          target: props.event || 'button-clicked',
        });
    }}
    {...props}
  />
);
