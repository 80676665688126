import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import {
  COL_MAIN,
  COL_TEXT,
  COL_DARK,
  FONT_HEADER,
  M,
  L,
  XL,
} from '../variables';
import { Logo } from './Logo';

const FooterSection = styled.footer`
  background: ${COL_MAIN};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: ${XL} 0 ${L} 0;
`;

const Items = styled.ul`
  flex: 0 0 auto;
  display: flex;
  margin: ${L} 0 0 0;
`;

const Item = styled.li`
  flex: 0 0 auto;
  display: flex;
  margin: 0;
  font-family: ${FONT_HEADER.join(', ')};

  a {
    display: block;
    color: ${COL_TEXT};
    text-decoration: none;
    font-weight: bold;
    font-size: 1.1rem;
    padding: 0 ${M};
  }

  a:hover {
    color: ${COL_DARK};
    text-decoration: underline;
  }
`;

export const Footer = () => (
  <FooterSection>
    <Logo />
    <Items>
      <Item>
        <Link to="/">Home</Link>
        <Link to="/datenschutz">Datenschutz</Link>
        <Link to="/impressum">Impressum</Link>
      </Item>
    </Items>
  </FooterSection>
);
