import React from 'react';
import styled from '@emotion/styled';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';
import {
  COL_MAIN,
  COL_DARK,
  COL_TEXT,
  PADDING_CONTENT,
  WIDTH_CONTENT,
  S,
  M,
  FONT_HEADER,
  BREAKPOINT,
} from '../variables';
import { Logo } from './Logo';

const Nav = styled.nav`
  background: ${COL_MAIN};
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 0;
  z-index: 100;
`;

const InnerNav = styled.div`
  background: ${COL_MAIN};
  width: ${WIDTH_CONTENT};
  max-width: 100%;
  padding: ${M} ${PADDING_CONTENT};
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Items = styled.ul`
  flex: 0 0 auto;
  display: flex;
  margin: 0;
`;

const Item = styled.li`
  flex: 0 0 auto;
  display: flex;
  margin: 0;
  font-family: ${FONT_HEADER.join(', ')};

  a {
    display: block;
    color: ${COL_TEXT};
    text-decoration: none;
    font-weight: bold;
    font-size: 1.25rem;
    padding: 0 ${M};

    ${BREAKPOINT} {
      font-size: 1rem;
      padding: 0 ${S};
    }
  }

  a:hover {
    color: ${COL_DARK};
    text-decoration: underline;
  }
`;

const LocalLink = ({ to, children, isRoot }) => {
  console.log(to, isRoot);
  return isRoot ? (
    <AnchorLink offset="60" href={`#${to}`}>
      {children}
    </AnchorLink>
  ) : (
    <Link to={`/#${to}`}>{children}</Link>
  );
};

export const Navigation = ({ isRoot }) => (
  <Nav>
    <InnerNav>
      <Logo />
      <Items>
        <Item>
          <LocalLink isRoot={isRoot} to="stories">
            Publikationen
          </LocalLink>
          {/* <LocalLink to="blog">Blog</LocalLink> */}
          <LocalLink isRoot={isRoot} to="autoren">
            Autoren
          </LocalLink>
          <LocalLink isRoot={isRoot} to="kontakt">
            Kontakt
          </LocalLink>
        </Item>
      </Items>
    </InnerNav>
  </Nav>
);
