import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { COL_BRIGHT, COL_DARK, FONT_HEADER } from '../variables';

const Box = styled(Link)`
  display: block;
  text-align: center;
  font-family: ${FONT_HEADER.join(', ')};
  font-weight: bold;
  line-height: 0.8;
  text-decoration: none;
  cursor: pointer;
`;
const Am = styled.span`
  display: block;
  color: ${COL_BRIGHT};
  font-size: 2rem;
`;
const Reis = styled.span`
  display: block;
  color: ${COL_DARK};
  font-size: 1.4rem;
`;

export const Logo = () => (
  <Box to="/">
    <Am>AM</Am>
    <Reis>REIS</Reis>
  </Box>
);
