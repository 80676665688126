import React, { Fragment, ReactNode } from 'react';
import Helmet from 'react-helmet';

type SeoProps = {
  title?: string;
  description?: string;
  keywords?: string[];
};

export const Seo = ({ title, description, keywords = [] }: SeoProps) => {
  const pageTitle = title || 'Science Fiction und Fantasy Autoren';
  return (
    <Helmet
      htmlAttributes={{
        lang: 'de',
      }}
      title={pageTitle}
      titleTemplate={`%s | A. M. Reis`}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: pageTitle,
        },
        {
          property: 'og:image',
          content: 'https://amreis.de/og.jpg',
        },
        {
          property: 'og:image:width',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '1224',
        },
        {
          property: 'twitter:image',
          content: 'https://amreis.de/og.jpg',
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: 'A. M. Reis',
        },
        {
          name: 'twitter:title',
          content: pageTitle,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, user-scalable=no',
        },
        {
          name: 'google-site-verification',
          content: 'B46JXwDIF5wzjnInawA2FohWk8ZXNIriPwhMw5JhjDU',
        },
        {
          name: 'p:domain_verify',
          content: '23897420e5b09ca3f9a51776cfee54de',
        },
      ].concat(
        keywords.length > 0
          ? {
              name: 'keywords',
              content: keywords.join(', '),
            }
          : []
      )}
    />
  );
};
