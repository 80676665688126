import React, { createContext } from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';

const { Provider, Consumer } = createContext(null);

const ImageContainer = styled.div``;

export const ImageProvider = ({ children }) => (
  <StaticQuery
    query={graphql`
      {
        allFile {
          edges {
            node {
              name
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const imageMap = data.allFile.edges.reduce((result, node) => {
        result[node.node.name] = node.node.childImageSharp.fluid;
        return result;
      }, {});
      return <Provider value={{ imageMap }}>{children}</Provider>;
    }}
  />
);

type ImageProps = {
  className?: string;
  name: string;
  alt?: string;
  width?: number;
};

export const Image = ({ className, name, alt, width }: ImageProps) => (
  <Consumer>
    {({ imageMap }) => {
      const fluid = imageMap[name];
      return (
        <ImageContainer className={className}>
          <GatsbyImage alt={alt} fluid={fluid} />
        </ImageContainer>
      );
    }}
  </Consumer>
);
